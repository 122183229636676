/**
 * SASS Entry point.
 */
import '../scss/main.scss';

/**
 * Loading Project modules:
 * When the page is ready, you can start
 * importing your source files here to include
 * in the bundle. Using the dynamic import() syntax
 * will trigger automatic chunk splitting. This is good
 * as an entrypoint to specific pages or other
 * situations when lazy loading modules is preferred
 * for performance reasons
 */
function init() {
  const gardenRoot = document.querySelector('main.garden');
  if(gardenRoot) {
    import('./modules/garden').then(({ default: Garden }) => {
      window.garden = new Garden(gardenRoot);
    });
  }
}

if (document.readyState === 'interactive') {
  init();
} else {
  document.addEventListener('DOMContentLoaded', init);
}
